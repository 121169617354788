$ppt_primary_color: #006600;
$ppt_secondary_color: #31ad31;

.gf-wrap-text {
  white-space: nowrap;
}

.gf_logo {
  width: 80px;
}

.gf_btn {
  background-color: $ppt_primary_color;
}

.gf_text {
  color: $ppt_primary_color;
}

.gf_btn:hover {
  background-color: $ppt_secondary_color;
  color: #fff;
}

.gf_btn:hover {
  background-color: $ppt_secondary_color;
  color: #fff;
}

.grecaptcha-badge {
  display: none !important;
}

.login-page .grecaptcha-badge {
  display: block !important;
}

select.gf_border {
  border: 1px solid #006600;
  padding-left: 20px;
  border-radius: 10px 0 0 10px;
  flex: 1 1 auto;
}

// SKELETON
.skeleton {
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
  animation: loading 1.5s infinite linear;
}

.skeleton-input,
.skeleton-icon,
.skeleton-button {
  width: 100%;
  height: 38px;
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
}

.skeleton-input::after,
.skeleton-icon::after,
.skeleton-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.05), transparent);
  animation: shadow-moving 1.5s infinite linear;
}

.skeleton-placeholder {
  min-height: 80px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.skeleton-placeholder::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}

.skeleton-header,
.skeleton-footer {
  height: 50px;
}

.skeleton-content {
  height: 100px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
}

.card-header.sh {
  position: relative;
}

.card-body.sh {
  position: relative;
}

.card-header.sh::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Light gray background */
  animation: loading 1.5s infinite linear;
}

.card-header.sh .header-top h6 {
  margin: 0;
  visibility: hidden; /* Hide content */
}

/* Skeleton styles for card body */
.card-body.sh {
  position: relative;
}

.card-body.sh::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Light gray background */
  animation: loading 1.5s infinite linear;
}

/* Skeleton styles for card content */
.card.sh {
  position: relative;
  overflow: hidden;
  border: none; /* Remove default card border */
}

.card.sh::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Light gray background */
  animation: loading 1.5s infinite linear;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes shadow-moving {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
// SKELETON

.shepherd-button-grey {
  background-color: rgb(217, 217, 217);
  color: #fff;
}

.shepherd-button-light-primary {
  background-color: rgb(147, 194, 140);
  color: #fff;
}

.shepherd-button-primary {
  background-color: rgb(41, 124, 29);
  color: #fff;
}

@media (max-width: 420px) {
  .lh {
    font-size: 10px;
  }
}

.my-swal-popup {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.my-swal-title {
  font-size: 24px;
  font-weight: bold;
  color: #2e2e2e; /* Darker color for contrast */
  margin-bottom: 10px;
}

.my-swal-text {
  font-size: 16px;
  color: #2e2e2e; /* Darker color for contrast */
  margin-bottom: 20px;
}

.my-swal-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fffce3; /* LimeGreen */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

.my-swal-icon svg {
  color: white;
  font-size: 40px;
}

.my-swal-confirm-button {
  background-color: #32cd32; /* LimeGreen */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.my-swal-confirm-button:hover {
  background-color: #28a745; /* Slightly darker green */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.my-swal-cancel-button {
  background-color: #ff6347; /* Tomato */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.my-swal-cancel-button:hover {
  background-color: #d9534f; /* Slightly darker red */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.swal2-container.swal2-center>.swal2-popup {
  width: 270px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.swal2-styled.swal2-confirm {
  background-color: #5dbc5d;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  z-index: 1000; /* Below the modal but above the rest of the content */
}

.modal-content {
  padding: 20px;
  max-height: 80vh; /* Limits the height so it's never more than 80% of the viewport */
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  cursor: pointer;
}

.popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.popup-modalr {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}