/**=====================
     Dashboard 5 CSS Start
==========================**/
.social {
  &-img {
    padding: 5px;
    background: var(--white);
    border-radius: 100%;
  }

  &-widget {
    .card-body {
      padding: 15px;
    }
    .social-icons {
      min-width: 55px;
      height: 55px;
      background: var(--white);
      box-shadow:
        0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704),
        0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296),
        0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1399px) {
        min-width: 40px;
        height: 40px;
        img {
          height: 20px;
        }
      }
    }
    .social-content {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      .social-chart {
        margin: -34px -42px -13px;
        width: 150px;
        .apexcharts-canvas {
          .apexcharts-datalabels-group {
            .apexcharts-datalabel-label {
              display: none;
            }
          }
        }
      }
    }
  }
}
.follower-chart {
  margin: -20px 0 -20px -34px;
  @media (max-width: 717px) {
    margin-left: 0;
  }
}
@keyframes wave-speaker {
  0% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(5px);
  }
}
.mobile-app-card {
  .card-header {
    background: transparent;
    padding: 20px;
    width: 68%;
    @media (max-width: 1693px) {
      width: 75%;
    }
    @media (max-width: 1316px) {
      width: 86%;
    }
    @media (max-width: 991px) {
      width: 64%;
    }
    @media (max-width: 350px) {
      width: 75%;
    }
  }
  .card-body {
    margin-top: -10px;
    @media (max-width: 1199px) {
      margin-top: 26px;
    }
    @media (max-width: 991px) {
      margin-top: 20px;
    }
    .wavy {
      width: 26px;
      margin-right: -37px;
      margin-top: -10px;
      animation: wave-speaker 2s ease infinite;
    }
  }
  .purchase-btn {
    padding: 10px 25px;
    min-width: unset;
  }
}
.subscriber-chart-container {
  margin: -22px -10px -24px;
}
.click-widgets {
  .card-body {
    padding: 28px 15px;
  }
  .click-chart {
    margin: -50px 0 -70px -20px;
  }
}
.follower-wrap {
  .card-body,
  .card-header {
    @media (max-width: 1693px) {
      padding: 22px;
    }
  }
  .card-header {
    padding-bottom: 20px;
  }
}
.campaign-list {
  column-count: 2;
  column-gap: 20px;
  margin-bottom: 15px;
  li {
    position: relative;
    + li {
      &::before {
        position: absolute;
        content: '';
        width: 1px;
        height: 20px;
        background-color: var(--chart-text-color);
        top: 50%;
        transform: translateY(-50%);
        left: -10px;
        opacity: 0.6;
        [dir='rtl'] & {
          left: unset;
          right: -10px;
        }
      }
    }
  }
  .campaign-box {
    background: linear-gradient(180deg, var(--course-light-btn) 0%, rgba(242, 243, 247, 0) 100%);
    border-radius: 5px;
    padding: 6px 10px;
  }
}
.social-circle {
  width: 34px;
  height: 34px;
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.campaign-table {
  table {
    tr {
      &:hover {
        td {
          &:nth-child(2) {
            color: var(--theme-deafult);
          }
        }
      }
    }
  }
  .border-icon {
    > div {
      border-left: 2px solid $fb-color;
      padding-left: 15px;
      [dir='rtl'] & {
        padding-left: unset;
        padding-right: 15px;
      }
    }
  }
  .facebook {
    > div {
      border-left: 2px solid $fb-color;
      [dir='rtl'] & {
        border-left: unset;
        border-right: 2px solid $fb-color;
      }
    }
    .social-circle {
      background-color: rgba($fb-color, 0.1);
      border-color: $fb-color;
      color: $fb-color;
    }
  }
  .instagram {
    > div {
      border-left: 2px solid $insta-color;
      [dir='rtl'] & {
        border-left: unset;
        border-right: 2px solid $insta-color;
      }
    }
    .social-circle {
      background-color: rgba($insta-color, 0.1);
      border-color: $insta-color;
      color: $insta-color;
    }
  }
  .pinterest {
    > div {
      border-left: 2px solid $pintrest-color;
      [dir='rtl'] & {
        border-left: unset;
        border-right: 2px solid $pintrest-color;
      }
    }
    .social-circle {
      background-color: rgba($pintrest-color, 0.1);
      border-color: $pintrest-color;
      color: $pintrest-color;
    }
  }
  .plus-btn {
    width: 24px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid var(--body-font-color);
    color: var(--body-font-color);
    &:hover {
      background-color: var(--body-font-color);
      color: var(--white);
    }
  }
  .twitter {
    > div {
      border-left: 2px solid $twitter-color;
      [dir='rtl'] & {
        border-left: unset;
        border-right: 2px solid $twitter-color;
      }
    }
    .social-circle {
      background-color: rgba($twitter-color, 0.1);
      border-color: $twitter-color;
      color: $twitter-color;
    }
  }
  .you-tube {
    > div {
      border-left: 2px solid $youtube-color;
      [dir='rtl'] & {
        border-left: unset;
        border-right: 2px solid $youtube-color;
      }
    }
    .social-circle {
      background-color: rgba($youtube-color, 0.1);
      border-color: $youtube-color;
      color: $youtube-color;
    }
  }
  .badge-light-primary,
  .badge-light-light {
    padding: 4px 12px;
  }
  tr {
    td,
    th {
      border-style: dashed;
      &:nth-child(5) {
        min-width: 190px;
      }
    }
  }
  .recent-table {
    tr {
      td,
      th {
        &:nth-child(2) {
          min-width: 120px;
        }
        &:first-child {
          min-width: 110px;
        }
        &:nth-child(3),
        &:last-child {
          min-width: 50px;
        }
      }
    }
  }
}

.view-container {
  .apexcharts-canvas {
    .apexcharts-grid-row {
      stroke-width: 15px;
      stroke: var(--white);
    }
    .apexcharts-gridlines-vertical,
    .apexcharts-gridlines-horizontal {
      line,
      react {
        stroke-width: 15px;
        stroke: var(--white);
      }
    }
    .apexcharts-point-annotations {
      circle {
        outline: 25px solid #f0effd;
        border-radius: 100%;
        @media (max-width: 480px) {
          outline-width: 10px;
        }
      }
    }
  }
}
