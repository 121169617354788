/**=====================
    83. Wishlist CSS start
==========================**/
.wishlist {
  table {
    tr {
      td {
        svg {
          color: $dark-gray;
          cursor: pointer;
        }
      }
    }
  }
}
/**=====================
    83. Wishlist CSS Ends
==========================**/
