/* You can add global styles to this file, and also import other style files */
@import './assets/scss/app.scss';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~leaflet-draw/dist/leaflet.draw.css";

.ng-select {
  width: 100%;
}

.ng-select .ng-select-container {
  width: 100%;
  box-sizing: border-box;
}

.ng-select .ng-select-searchable {
  width: 100%;
}

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}
